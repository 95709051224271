import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'

import iconBanner from '../images/icon/RI/icon-banner-ri.svg'

import './styles/informacoes-financeiras.scss'
import RenderForm from 'gatsby-plugin-cloudofertas'

const customCheckbox = (field, register, errors) => {
  console.log('field', field)

  return (
    <>
      <label className="select-box">
        <input type="checkbox"
          {...register(field.name, {
            required: field.required
          })} />
        <span className="checkmark"></span>
        <span className='ml-lg-3 link-style' dangerouslySetInnerHTML={{ __html: field.label }}></span>
      </label>
    </>
  )
}

const customFields = {
  politicatermos: customCheckbox
}

const InformacoesFinanceiras = ({ data }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <>
      <Pagina pagina={data.pagina} />
      <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
      <div className='bg-ri py-5'>
        {data.cloudOfertasForm.formTypeField && submitSuccess === false &&
          <RenderForm
            formData={data.cloudOfertasForm}
            setSuccessSubmit={setSubmitSuccess}
            placeholder={false}
            customFields={customFields}
            containerStyle='container'
            rowStyle='row'
            inputStyle='custom-form-ri col-12'
            btnContainerStyle='custom-form-ri col'
            btnStyle='btn btn-block'
            btnName='Enviar'
          />
        }
        {submitSuccess &&
          <div className='container mt-0'>
            <div className="text-center font-Spinnaker">
              <p>Obrigado por entrar em contato com a gente. Responderemos em até 48h.</p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-flex d-none"
      >
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100 position-relative">
            <div className='vertical-line position-absolute'></div>
            <div className="col-2 p-0">
              <img src={iconBanner} className='w-90' />
            </div>
            <div className="col-5 p-0">
              <h1 className="text-white">Fale com RI</h1>
              <p className="text-white">Envie sugestões, dúvidas e comentários para nosso e-mail centraltesouraria@lwart.com.br ou use o formulário abaixo.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>

      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-none d-flex"
      >
        <div className="container h-100 position-relative d-flex align-items-center">
          <div className='vertical-line position-absolute'></div>
          <div>
            <div className='d-flex align-items-center'>
              <img src={iconBanner} className='w-20 mr-3 ml-4' />
              <h1 className="text-white">Fale com RI</h1>
            </div>
            <div className='mt-3 ml-4'>
              <p className="text-white">Envie sugestões, dúvidas e comentários para nosso e-mail centraltesouraria@lwart.com.br ou use o formulário abaixo.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

export default InformacoesFinanceiras

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/informacoes-financeiras/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "RI/banner-ri.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  cloudOfertasForm(name: {eq: "FALE_COM_RI"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
      formLov {
        formLovData {
          id
          value
          createdDate
        }
      }
    }
  }
}
`
